import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import { pdf } from '@react-pdf/renderer';
import PdfGenerator from '../../components/PdfGenerator';
import { CSVLink } from 'react-csv';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const ItemPage = () => {
  const [itemData, setItemData] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const [pdfData, setPdfData] = useState({
    tableTitles: [],
    tableContents: [],
  });
  const state = useContext(GlobalStateData);
  useEffect(() => {
    authCheck(state.authData);
    getCustomers();
    getSorts('');
    getPOCs('');
  }, []);

  useEffect(() => {
    if (tableData.length) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [tableData]);

  const pdfTitles = [
    'Lot',
    'Iss Mt',
    'Sound',
    'Short Len',
    'Seconds',
    'Fents',
    'Rags',
    'Others',
    'Pkd Mts',
    'Diff',
    'Elong Mts',
    'Elong %',
    'Shrink Mts',
    'Shrink %',
  ];
  const localNumberGetter = (num) => {
    if (num === undefined) {
      num = 0;
    }
    num = +num;
    return num.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  };
  const postApi = () => {
    const postData = {
      data: {
        customer,
        customerSort,
        poc,
      },
    };
    let currPoc;
    let validate = requireCheck(postData);

    if (!validate) {
      return false;
    }
    if (defaultPOC[0].label === 'All') {
      currPoc = poc.map((t) => t.value);
      currPoc = currPoc.slice(1, currPoc.length);
    } else {
      currPoc = defaultPOC.map((t) => t.value);
    }
    currPoc = currPoc.join(',');
    showLoader();
    axios
      .get(baseUri() + `grey-receipt-advice/realisation-report?poc=${currPoc}`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const pdfCont = [];
        setItemData(response.data.data.result);
        let totCont = {
          Issue_Meters: 0,
          Sound: 0,
          Short_Length: 0,
          Seconds: 0,
          Fents: 0,
          Rags: 0,
          Others: 0,
          Packed_Mts: 0,
          Diff: 0,
          Elongation: 0,
          Shrinkage: 0,
        };
        let resp = response.data.data.result;
        let currPocId = response.data.data.result[0]?.POC_ID;
        let currHeadingStr = `${resp[0]?.Customer_Name}-${resp[0]?.Sort_Name}-${resp[0]?.POC_ID}`;
        response.data.data.result.forEach((t, i) => {
          if (t.POC_ID === currPocId) {
            if (i === 0) {
              pdfCont.push([currHeadingStr]);
            }
            totCont.Issue_Meters += +t.Issue_Meters;
            totCont.Sound += +t.Sound;
            totCont.Short_Length += +t.Short_Length;
            totCont.Seconds += +t.Seconds;
            totCont.Fents += +t.Fents;
            totCont.Rags += +t.Rags;
            totCont.Others += +t.Others;
            totCont.Packed_Mts += +t.Packed_Mts;
            totCont.Diff += +t.Diff;
            totCont.Elongation += +t.Elongation;
            totCont.Shrinkage += +t.Shrinkage;
          } else {
            currHeadingStr = `${t.Customer_Name}-${t.Sort_Name}-${t.POC_ID}`;
            const diffMeters = +totCont.Packed_Mts - +totCont.Issue_Meters;
            const elongPercent = (+totCont.Elongation / +totCont.Issue_Meters) * 100;
            const shrinkPercent = (+totCont.Shrinkage / +totCont.Issue_Meters) * 100;
            pdfCont.push([
              '',
              localNumberGetter(totCont.Issue_Meters),
              localNumberGetter(totCont.Sound),
              localNumberGetter(totCont.Short_Length),
              localNumberGetter(totCont.Seconds),
              localNumberGetter(totCont.Fents),
              localNumberGetter(totCont.Rags),
              localNumberGetter(totCont.Others),
              localNumberGetter(totCont.Packed_Mts),
              localNumberGetter(totCont.Diff),
              diffMeters >= 0 ? localNumberGetter(diffMeters) : '',
              diffMeters >= 0 ? localNumberGetter(elongPercent) : '',
              diffMeters < 0 ? localNumberGetter(diffMeters) : '',
              diffMeters < 0 ? localNumberGetter(shrinkPercent) : '',
            ]);
            pdfCont.push([currHeadingStr]);
            totCont.Issue_Meters = +t.Issue_Meters || 0;
            totCont.Sound = +t.Sound || 0;
            totCont.Short_Length = +t.Short_Length || 0;
            totCont.Seconds = +t.Seconds || 0;
            totCont.Fents = +t.Fents || 0;
            totCont.Rags = +t.Rags || 0;
            totCont.Others = +t.Others || 0;
            totCont.Packed_Mts = +t.Packed_Mts || 0;
            totCont.Diff = +t.Diff || 0;
            totCont.Elongation = +t.Elongation || 0;
            totCont.Shrinkage = +t.Shrinkage || 0;
            currPocId = t.POC_ID;
          }
          pdfCont.push([
            t.lot_num,
            localNumberGetter(t.Issue_Meters),
            localNumberGetter(t.Sound),
            localNumberGetter(t.Short_Length),
            localNumberGetter(t.Seconds),
            localNumberGetter(t.Fents),
            localNumberGetter(t.Rags),
            localNumberGetter(t.Others),
            localNumberGetter(t.Packed_Mts),
            localNumberGetter(t.Diff),
            localNumberGetter(t.Elongation),
            localNumberGetter(t.Elongation_PCT),
            localNumberGetter(t.Shrinkage),
            localNumberGetter(t.Shrinkage_PCT),
          ]);
        });
        const diffMeters = +totCont.Packed_Mts - +totCont.Issue_Meters;
        const elongPercent = (+totCont.Elongation / +totCont.Issue_Meters) * 100;
        const shrinkPercent = (+totCont.Shrinkage / +totCont.Issue_Meters) * 100;
        pdfCont.push([
          '',
          localNumberGetter(totCont.Issue_Meters),
          localNumberGetter(totCont.Sound),
          localNumberGetter(totCont.Short_Length),
          localNumberGetter(totCont.Seconds),
          localNumberGetter(totCont.Fents),
          localNumberGetter(totCont.Rags),
          localNumberGetter(totCont.Others),
          localNumberGetter(totCont.Packed_Mts),
          localNumberGetter(totCont.Diff),
          diffMeters >= 0 ? localNumberGetter(diffMeters) : '',
          diffMeters >= 0 ? localNumberGetter(elongPercent) : '',
          diffMeters < 0 ? localNumberGetter(diffMeters) : '',
          diffMeters < 0 ? localNumberGetter(shrinkPercent) : '',
        ]);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data.result);
        }
        setPdfData({
          tableContents: pdfCont,
          tableTitles: pdfTitles,
        });
        hideLoader();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [customer, setCustomer] = useState([]);
  const [defaultCustomer, setDefaultCustomer] = useState([{ label: 'All', value: '' }]);
  const [userOptions, setUserOptions] = useState([]);
  const [customerSort, setCustomerSort] = useState([]);
  const [poc, setPoc] = useState([]);
  const [defaultCustomerSort, setDefaultCustomerSort] = useState([{ label: 'All', value: '' }]);
  const [defaultPOC, setDefaultPOC] = useState([{ label: 'All', value: '' }]);
  const [sortOptions, setSortOptions] = useState([]);

  const handleCustomerChange = (event) => {
    let selected = [];
    let fliterStr = [];
    let pocFilterStr = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      if (item.value) {
        selected.push(item.value);
        fliterStr.push(`filters[user][id][$in][${index}]=${item.value}`);
        pocFilterStr.push(`filters[customer][id][$in][${index}]=${item.value}`);
      }
    });
    setCustomer(selected);
    setDefaultCustomer(event);
    pullDownReset('customer');
    setCustomerSort([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    setDefaultPOC([{ label: 'All', value: '' }]);
    setSortOptions([]);
    setPoc([]);
    getSorts(fliterStr.join('&'));
    getPOCs(pocFilterStr.join('&'));
  };

  const handleCustomerSortChange = (event) => {
    let selected = [];
    let filterStr = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      selected.push(item.value);
      filterStr.push(`filters[customersSort][id][$in][${index}]=${item.value}`);
    });
    customer.forEach((item, index) => {
      filterStr.push(`filters[customer][id][$in][${index}]=${item}`);
    });
    setCustomerSort(selected);
    setDefaultCustomerSort(event);
    setPoc([]);
    setDefaultPOC([{ label: 'All', value: '' }]);
    getPOCs(filterStr.join('&'));
    pullDownReset('customerSort');
  };

  const handlePOCChange = (event) => {
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    setDefaultPOC(event);
    pullDownReset('poc');
  };

  const clearFormData = () => {
    getSorts('');
    getPOCs('');
    setCustomer([]);
    setDefaultCustomer([{ label: 'All', value: '' }]);
    setCustomerSort([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    setPoc([]);
    setDefaultPOC([{ label: 'All', value: '' }]);
    resetRequired();
  };

  const getCustomers = () => {
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=4', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setUserOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getSorts = (query) => {
    setCustomerSort([]);
    showLoader();
    axios
      .get(baseUri() + `user-sorts?${query}`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.sortName, value: item.id });
        });
        setSortOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getPOCs = (query) => {
    setPoc([]);
    showLoader();
    axios
      .get(`${baseUri()}process-orders?fields[0]=status&${query}`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        // response.data.data.forEach(function (item, index) {
        //   options.push({ label: item.attributes.sortName, value: item.id });
        // });
        // setSortOptions(options);
        response.data.data.forEach((t) => {
          options.push({
            label: t.id,
            value: t.id,
          });
        });
        setPoc(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const columns = [
    {
      name: 'JCN',
      selector: (row) => `${row.JCN}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Lot Num',
      selector: (row) => `${row.lot_num}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'POC ID',
      selector: (row) => `${row.POC_ID}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Issue Meters',
      selector: (row) => `${row.Issue_Meters}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Sound',
      selector: (row) => `${row.Sound}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Short Length',
      selector: (row) => `${row.Short_Length}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Seconds',
      selector: (row) => `${row.Seconds}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Fents',
      selector: (row) => `${row.Fents}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Rags',
      selector: (row) => `${row.Rags}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Others',
      selector: (row) => `${row.Others}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Packed Mts',
      selector: (row) => `${row.Packed_Mts}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Diff',
      selector: (row) => `${row.Diff}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Elongation',
      selector: (row) => `${row.Elongation}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Elongation PCT',
      selector: (row) => `${row.Elongation_PCT}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Shrinkage',
      selector: (row) => `${row.Shrinkage}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Shrinkage PCT',
      selector: (row) => `${row.Shrinkage_PCT}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const filterColumns = [
    'JCN',
    'lot_num',
    'POC_ID',
    'Issue_Meters',
    'Sound',
    'Short_Length',
    'Seconds',
    'Fents',
    'Rags',
    'Others',
    'Packed_Mts',
    'Diff',
    'Elongation',
    'Elongation_PCT',
    'Shrinkage',
    'Shrinkage_PCT',
  ];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      <SEO title="Realisation Report Generator" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Realisation Report Generator</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Customer</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerChange(e)}
                    options={userOptions}
                    id="customer"
                    name="customer"
                    placeholder="Select Customer *"
                    value={defaultCustomer}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Customer Sort</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerSortChange(e)}
                    options={sortOptions}
                    id="customerSort"
                    name="customerSort"
                    placeholder="Select Customer Sort"
                    value={defaultCustomerSort}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    POC <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handlePOCChange(e)}
                    options={poc}
                    id="poc"
                    name="poc"
                    placeholder="Select POC"
                    value={defaultPOC}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button status="Success" type="button" className="btnrad" shape="" halfWidth onClick={postApi}>
                    Get Report
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Realisation Report</header>
            <CardBody>
              {showDownload && (
                <p>
                  <PdfGenerator documentTitle="realisation-report" data={pdfData} />{' '}
                  <CSVLink data={tableData}>Download Excel</CSVLink>
                </p>
              )}
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ItemPage;
